<template>
  <div class="max-w-4xl mx-auto p-6 bg-white">
    <p class="text-sm text-gray-600 mb-8 text-center">
      <span class="font-semibold uppercase">Directions:</span> Different situations call for different reactions. This is why people can possess opposing qualities at the same time. On the list below, please mark the box in front of the pairs of opposing qualities that best describe you. Check
      <span class="font-semibold">ONLY</span> the boxes where
      <span class="font-semibold">BOTH</span> qualities apply to you, not just one of them.
    </p>

    <!-- Opposing Qualities Grid -->
    <form @submit.prevent="validateAndSubmit" class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div v-for="(pair, index) in qualities" :key="index" class="flex items-center">
        <input
          type="checkbox"
          :id="'quality-' + index"
          v-model="pair.selected"
          class="mr-3 h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500"
        />
        <label :for="'quality-' + index" class="text-gray-800 cursor-pointer">
          {{ pair.quality1 }} + {{ pair.quality2 }}
        </label>
      </div>

      <!-- Submit Button -->
      <div class="flex justify-end mt-8 col-span-full">
        <button
          type="submit"
          class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const emit = defineEmits(["valid-submit"]);

const qualities = ref([
  { quality1: "SENSITIVE", quality2: "TOUGH", selected: false },
  { quality1: "COOPERATIVE", quality2: "REBELLIOUS", selected: false },
  { quality1: "STRONG", quality2: "GENTLE", selected: false },
  { quality1: "PROUD", quality2: "HUMBLE", selected: false },
  { quality1: "COWARDLY", quality2: "COURAGEOUS", selected: false },
  { quality1: "SELFISH", quality2: "UNSELFISH", selected: false },
  { quality1: "MATURE", quality2: "PLAYFUL", selected: false },
  { quality1: "INVOLVED", quality2: "DETACHED", selected: false },
  { quality1: "HUMOROUS", quality2: "SERIOUS", selected: false },
  { quality1: "LAZY", quality2: "HARDWORKING", selected: false },
  { quality1: "DISTANT", quality2: "FRIENDLY", selected: false },
  { quality1: "LOGICAL", quality2: "CREATIVE", selected: false },
  { quality1: "SELF-CONFIDENT", quality2: "SELF-CRITICAL", selected: false },
  { quality1: "CALM", quality2: "EMOTIONAL", selected: false },
  { quality1: "TRUSTING", quality2: "CAUTIOUS", selected: false },
  { quality1: "SHY", quality2: "BOLD", selected: false },
  { quality1: "DEPENDENT", quality2: "INDEPENDENT", selected: false },
  { quality1: "LOVING", quality2: "ANGRY", selected: false },
  { quality1: "IMPULSIVE", quality2: "WELL-ORGANIZED", selected: false },
  { quality1: "CONSISTENT", quality2: "UNPREDICTABLE", selected: false },
  { quality1: "HAPPY", quality2: "DISCONTENT", selected: false },
  { quality1: "MESSY", quality2: "NEAT", selected: false },
  { quality1: "OPTIMISTIC", quality2: "PESSIMISTIC", selected: false },
]);

const selectedQualities = computed(() => {
  return qualities.value.filter((pair) => pair.selected);
});

// Watch for changes in the selected qualities to update the store
watch(selectedQualities, (newVal) => {
  authStore.kurtzResults.qualities = newVal;
});

const submitSelection = () => {
  emit("valid-submit");
};

const validateAndSubmit = () => {
  return new Promise((resolve, reject) => {
    // Check if at least one quality pair is selected
    if (selectedQualities.value.length === 0) {
      alert("Please select at least one pair of opposing qualities that describe you.");
      authStore.kurtzResults.errorTracking.qualities++;
      reject(new Error('Validation failed'));
      return;
    }

    authStore.kurtzResults.qualities = selectedQualities.value;
    emit("valid-submit");
    resolve();
  });
};
</script>

<style scoped>
input:checked + label {
  background-color: #e0f7fa;
  border-radius: 4px;
  padding: 4px;
}
</style>
