<template>
  <div v-if="!hasAcceptedPolicies" class="max-w-5xl mx-auto bg-white rounded-xl shadow-md p-6">
    <h2 class="text-2xl font-semibold mb-4">Protocase/45Drives</h2>
    
    <!-- Policy content container -->
    <div class="policy-content mb-6 h-[60vh] overflow-y-auto border rounded-lg p-6 bg-gray-50">
      <div class="prose prose-sm max-w-none" v-html="parsedPolicyContent"></div>
    </div>

    <!-- Agreement section -->
    <div class="border-t pt-4 mt-4">
      <div class="flex items-start space-x-3 mb-6">
        <input 
          v-model="isAgreed" 
          type="checkbox" 
          id="policy-agreement"
          class="mt-1"
        />
        <label for="policy-agreement" class="text-gray-700">
          I, {{ userName }}, confirm that I have read and agree to comply with all Protocase/45Drives as outlined above.
        </label>
      </div>

      <button 
        @click="submitAgreement" 
        :disabled="!isAgreed"
        class="w-full bg-green-500 text-white font-medium py-2 px-4 rounded-md hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Confirm Agreement
      </button>
    </div>
  </div>
  <div v-else class="max-w-5xl mx-auto text-center py-12">
    <h2 class="text-2xl font-semibold text-gray-900 mb-4">Protocase/45Drives Policies Already Accepted</h2>
    <p class="text-gray-600 mb-6">You have already accepted the company policies on {{ formatDate(acceptedDate) }}</p>
    <div class="flex justify-center space-x-4">
      <router-link to="/" class="inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
        Return to Dashboard
      </router-link>
      <button
        @click="openPrintView"
        class="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
      >
        <svg class="-ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
        </svg>
        Print Version
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import MarkdownIt from 'markdown-it';
import { database, ID } from '@/lib/appwrite';

const md = new MarkdownIt({
  html: true,
  breaks: true,
  linkify: true,
  typographer: true
});

const authStore = useAuthStore();
const router = useRouter();

const isAgreed = ref(false);
const policyText = ref('');
const userName = ref(authStore.user?.name || '');

const hasAcceptedPolicies = computed(() => {
  return authStore.user?.prefs?.policies_accepted === true;
});

const acceptedDate = computed(() => {
  return authStore.user?.prefs?.policies_accepted_date;
});

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString();
};

// Compute parsed markdown content
const parsedPolicyContent = computed(() => {
  return md.render(policyText.value);
});

// Load policy text
onMounted(async () => {
  try {
    const response = await fetch('/assets/policies.md');
    policyText.value = await response.text();
  } catch (error) {
    console.error('Failed to load policy text:', error);
  }
});

// Redirect if already accepted
onMounted(() => {
  if (hasAcceptedPolicies.value && route.name === 'PolicyAgreement') {
    router.push('/');
  }
});

const submitAgreement = async () => {
  try {
    // Save agreement record to database
    const agreementData = {
      user: userName.value,
      accepted: true,
      userId: authStore.user.$id
    };

    // Save to policy agreements collection
    await database.createDocument(
      authStore.databaseId,        
      '66fc3948001859ac7cec',
      ID.unique(),                
      agreementData
    );

    // Update user preferences
    await authStore.updatePrefs({
      policies_accepted: true,
      policies_accepted_date: new Date().toISOString()
    });
    
    // Generate and download PDF after successful agreement
    await downloadPolicyPDF();
    
    router.push('/');
  } catch (error) {
    console.error('Failed to submit policy agreement:', error);
  }
};

const downloadPolicyPDF = async () => {
  try {
    const pdfData = {
      html: parsedPolicyContent.value,
      userName: userName.value,
      timestamp: acceptedDate.value || new Date().toISOString(),
      watermark: true,
      footer: `Acknowledged by ${userName.value} on ${formatDate(acceptedDate.value || new Date())}`
    };

    await authStore.generatePDF(pdfData);
  } catch (error) {
    console.error('Failed to download policy PDF:', error);
  }
};

const openPrintView = () => {
  // Open new window with printer-friendly version
  const printWindow = window.open('', '_blank');
  printWindow.document.write(`
    <html>
      <head>
        <title>Protocase/45Drives Policies</title>
        <style>
          body { 
            font-family: Arial, sans-serif; 
            line-height: 1.6; 
            padding: 20px;
            max-width: 1200px;
            margin: 0 auto;
          }
          h1, h2, h3 { margin-top: 20px; }
          .header { 
            text-align: center;
            margin-bottom: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ccc;
          }
          .acceptance-info {
            font-style: italic;
            color: #666;
            margin-bottom: 30px;
          }
          @media print {
            body { font-size: 12pt; }
            .page-break { page-break-before: always; }
          }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>Protocase/45Drives Policies</h1>
          <div class="acceptance-info">
            <p>Accepted by ${userName.value} on ${formatDate(acceptedDate.value)}</p>
          </div>
        </div>
        ${parsedPolicyContent.value}
      </body>
    </html>
  `);
  printWindow.document.close();
  // Wait for content to load then print
  setTimeout(() => printWindow.print(), 500);
};
</script>

<style>
.policy-content {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

.policy-content::-webkit-scrollbar {
  width: 6px;
}

.policy-content::-webkit-scrollbar-track {
  background: transparent;
}

.policy-content::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

/* Markdown content styling */
.prose h1 {
  @apply text-2xl font-bold mb-4 mt-6;
}

.prose h2 {
  @apply text-xl font-semibold mb-3 mt-5;
}

.prose h3 {
  @apply text-lg font-medium mb-2 mt-4;
}

.prose ul {
  @apply list-disc pl-5 mb-4;
}

.prose ol {
  @apply list-decimal pl-5 mb-4;
}

.prose li {
  @apply mb-1;
}

.prose p {
  @apply mb-4;
}

.prose strong {
  @apply font-semibold;
}

.prose em {
  @apply italic;
}

.prose blockquote {
  @apply pl-4 border-l-4 border-gray-300 italic my-4;
}

.prose code {
  @apply bg-gray-100 px-1 rounded;
}

.prose pre {
  @apply bg-gray-100 p-4 rounded-lg mb-4 overflow-x-auto;
}

.prose a {
  @apply text-blue-600 hover:underline;
}
</style>