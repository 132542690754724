<template>
  <div class="fixed inset-0 z-10 overflow-y-auto mt-40 max-h-screen" v-if="!loading">
    <div
      class="flex flex-col min-h-full items-center justify-center p-4 text-center sm:p-0"
    >
      <!-- download as pdf -->
      <button
        @click="createPDF"
        class="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600"
      >
        Download as PDF
      </button>

      <div
        id="pdf-data"
        ref="pdfData"
        class="mx-auto bg-white p-8 rounded-lg border border-gray-300 space-y-8"
      >
        <h1 class="text-4xl font-medium leading-6 text-gray-900">
          {{ result?.Name || "Loading..." }}
          
        </h1>

        <!-- Descriptors Section -->
        <section class="bg-white shadow-md p-8 text-sm mb-6 rounded-lg max-w-5xl mx-auto relative">
          <h2 class="text-center mb-1 underline">DESCRIPTORS</h2>
          <p class="text-gray-700 mb-1 text-left mx-auto">
            What follows are descriptors. Please read them over carefully and think about
            yourself. Place check marks only beside those which you feel best describe
            you. Remember, there are no "bad" or "wrong" descriptors, just those which
            best personify you.
          </p>
          <div class="grid grid-cols-2 gap-1 text-left max-w-3xl mx-auto">
            <div
              v-for="(descriptor, index) in updatedDescriptors"
              :key="index"
              class="flex items-center"
            >
              <span
                class="underline underline-offset-4 font-semibold mr-4"
                v-if="descriptor.checked"
                >✓</span
              >
              <span v-else class="underline underline-offset-4 font-semibold mr-4">
                &nbsp;
              </span>
              <!-- Add a space to maintain alignment -->

              <span class="text-gray-800">{{ descriptor.name }}</span>
            </div>
          </div>
    
          <div v-if="parseJSON(result?.errorTracking)?.descriptorsselection > 0" class="absolute bottom-2 left-2 text-sm font-bold text-red-500">R</div>
        </section>

        <!-- Adjective Checklist Section -->
        <section
          class="bg-white shadow-lg p-2 text-sm mb-6 rounded-lg max-w-5xl mx-auto page-break relative"
        >
          <h2 class="text-md text-center mb-6 underline">ADJECTIVE CHECKLIST</h2>
          <p class="text-gray-700 mb-6 text-center">
            Below you will find a list of descriptive adjectives. On the line in front of
            each description, please rate yourself on a scale from 1‑10 (1 being the
            least, 10 being the most), then check the column which you feel best portrays
            the description (the presumption being skills can be learned and traits cannot
            be learned).
          </p>

          <!-- Adjective List -->
          <div class="grid grid-cols-3 gap-4 font-medium text-gray-800">
            <div class="col-span-1 text-left">ADJECTIVE</div>
            <div class="col-span-1 text-left">TRAIT</div>
            <div class="col-span-1 text-left">SKILL</div>
          </div>
          <div class="border-b-2 border-gray-300 mb-6"></div>

          <!-- Each adjective row -->
          <div
            v-for="(adjective, index) in parsedAdjectives"
            :key="index"
            class="grid grid-cols-3 gap-4 items-center mb-4"
          >
            <!-- Adjective Rating -->
            <div class="flex items-center">
              <span class="mr-4 underline underline-offset-2">{{
                adjective.rating
              }}</span>
              <span>{{ adjective.adjective }}</span>
            </div>

            <!-- Trait Checkbox -->
            <div class="flex underline underline-offset-4 w-10">
              <span v-if="adjective.isTrait">✓</span>
              <span v-else> &nbsp; </span>
            </div>

            <!-- Skill Checkbox -->
            <div class="flex underline underline-offset-4 w-10">
              <span v-if="adjective.isSkill">✓</span>
              <span v-else> &nbsp; </span>
            </div>
          </div>
          <div v-if="parseJSON(result?.errorTracking)?.adjectivechecklist > 0" class="absolute bottom-2 left-2 text-sm font-bold text-red-500">R</div>
        </section>

        <section
          class="bg-white shadow-lg p-2 mb-1 rounded-lg max-w-5xl text-sm mx-auto page-break relative"
        >
          <h2 class="text-sm text-center mb-1 underline">Color Preferences</h2>

          <!-- Color Sections -->
          <div
            v-for="(colorSheet, index) in parsedColorSheets"
            :key="index"
            :class="`bg-${colorSheet.color.toLowerCase()}-100 p-1 rounded-lg mb-1`"
          >
            <h3
              :class="`text-lg font-semibold text-${colorSheet.color.toLowerCase()}-800 mb-1`"
            >
              {{ colorSheet.color.toUpperCase() }}
            </h3>
            <ul
              :class="`pl-6 text-left text-${colorSheet.color.toLowerCase()}-700 grid grid-cols-2`"
            >
              <li
                class="list-disc"
                v-for="(description, descIndex) in colorSheet.description"
                :key="descIndex"
              >
                {{ description }}
              </li>
            </ul>
            <div class="text-right mt-1">
              <span class="text-lg font-semibold">Rank: {{ colorSheet.rank }}</span>
            </div>
          </div>
          <div v-if="parseJSON(result?.errorTracking)?.colorranking > 0" class="absolute bottom-2 left-2 text-sm font-bold text-red-500">R</div>
        </section>

        <!-- Word Square Section -->
        <section
          class="bg-white shadow-lg p-2 mb-1 rounded-lg max-w-5xl mx-auto page-break relative"
        >
          <h2 class="text-lg text-center underline">Word Square</h2>

          <p class="mb-1 text-sm">
            In the first horizontal row of the four squares you find below, decide which
            group of three words seems <em>most</em> like you - and put a 4 in that
            square.
          </p>
          <p class="mb-1 text-sm">
            The group of words in that same row that seems <em>next most</em> like you
            will rate a score of 3, another group will get a 2, and the group which seems
            the <em>least</em> like you should be given a score of 1.
          </p>
          <p class="mb-1 text-sm">
            Then go on to the second horizontal row and score those squares in the same
            way: 4 for the group that's <em>most</em> like you - 3 and 2 for the ones
            in-between, and 1 for the group that's <em>least</em> like you, etc.
          </p>
          <!-- Word Square Table -->
          <div class="overflow-x-auto text-sm" v-if="parsedWordSquare.value">
            <table class="min-w-full border border-gray-400">
              <thead>
                <tr>
                  <th
                    class="w-1/4 bg-gray-200 text-left px-4 py-2 border border-gray-400"
                  >
                    Row
                  </th>
                  <th
                    v-for="(column, colIndex) in parsedWordSquare.value[0].groups"
                    :key="'header-' + colIndex"
                    class="w-1/4 bg-white text-left px-4 py-2 border border-gray-400"
                  >
                    Column {{ colIndex + 1 }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, rowIndex) in parsedWordSquare.value"
                  :key="'row-' + rowIndex"
                >
                  <td class="bg-gray-200 text-left px-4 py-2 border border-gray-400">
                    Row {{ rowIndex + 1 }}
                  </td>
                  <td
                    v-for="(group, groupIndex) in row.groups"
                    :key="'group-' + rowIndex + '-' + groupIndex"
                    class="bg-white text-left px-4 py-2 border border-gray-400"
                  >
                    <span
                      v-for="(word, wordIndex) in group.words"
                      :key="'word-' + wordIndex"
                      >{{ word }}<br
                    /></span>
                    <span class="font-bold">{{ group.rating }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Totals Section -->
          <div class="mt-6 text-center">
            <p class="text-sm mb-4">
              Now add the numbers in each of the vertical columns and put the totals in
              the squares below:
            </p>
            <div class="flex justify-center space-x-8">
              <div
                v-for="(total, index) in parsedWordSquare.totals"
                :key="'total-' + index"
                class="w-16 h-16 border border-gray-400 flex items-center justify-center text-lg font-semibold"
              >
                {{ total }}
              </div>
            </div>
          </div>
          <div v-if="parseJSON(result?.errorTracking)?.wordSquare > 0" class="absolute bottom-2 left-2 text-sm font-bold text-red-500">R</div>
        </section>

        <!-- Opposing Qualities Section -->
        <section
          class="bg-white shadow-lg p-8 mb-6 text-sm rounded-lg max-w-5xl mx-auto page-break relative"
        >
          <p class="mb-6">
            Different situations call for different reactions. This is why people can
            possess opposing qualities at the same time. On the list below, please place a
            check mark in front of the pairs of opposing qualities that best describe you.
            <strong
              >Check ONLY the boxes where BOTH qualities apply to you, not just one of
              them.</strong
            >
          </p>

          <div class="grid grid-cols-2 gap-x-16 gap-y-4">
            <div
              v-for="(quality, index) in updatedQualities"
              :key="index"
              class="flex items-center"
            >
              <span
                v-if="quality.selected"
                class="text-lg font-bold text-black mr-2 w-8 border"
                >✓</span
              >
              <span v-else class="text-lg font-bold text-black mr-2 w-8 border">
                <!-- square -->
                &nbsp;
              </span>
              <label :for="'quality-' + index" class="text-gray-800">
                {{ quality.quality1 }} + {{ quality.quality2 }}
              </label>
            </div>
          </div>
          <div v-if="parseJSON(result?.errorTracking)?.qualities > 0" class="absolute bottom-2 left-2 text-sm font-bold text-red-500">R</div>
        </section>

        <section
          class="bg-white shadow-lg text-sm p-8 mb-6 rounded-lg max-w-5xl mx-auto page-break relative"
        >
          <!-- Instructions -->
          <p class="mb-8">
            <span class="italic font-semibold">Directions:</span> The items that follow
            reflect values associated with effective organizational operations. Circle the
            number that indicates your opinion of how each value relates to your ideal
            workplace.
          </p>

          <!-- Questions Loop -->
          <div
            v-for="(question, index) in parsedOrganizationalValues.questions"
            :key="index"
            class="mb-6"
          >
            <!-- Question Title -->
            <h3 class="font-semibold mb-2 underline text-left">{{ question.title }}</h3>
            <div class="flex items-center space-x-4 space-y-4">
              <!-- Low Label -->
              <span class="text-sm text-gray-700 w-48">{{ question.lowLabel }}</span>

              <!-- Rating Options -->
              <div class="flex space-x-3">
                <span
                  v-for="n in 7"
                  :key="n"
                  class="w-8 h-8 flex items-center justify-center border border-gray-400 rounded-full text-sm font-semibold"
                  :class="{
                    'bg-gray-300 text-black font-bold border-2 border-gray-900':
                      n === question.selected,
                  }"
                >
                  {{ n }}
                </span>
              </div>

              <!-- High Label -->
              <span class="text-sm text-gray-700 w-48 text-right">{{
                question.highLabel
              }}</span>
            </div>
          </div>

          <!-- Total Score -->
          <div class="mt-8 text-center font-semibold text-lg">
            Total of
            {{
              parsedOrganizationalValues.questions
                ? parsedOrganizationalValues.questions.length
                : 0
            }}
            circled numbers:
            {{ parsedOrganizationalValues.totalScore }}
          </div>
          <div v-if="parseJSON(result?.errorTracking)?.organizationalValues > 0" class="absolute bottom-2 left-2 text-sm font-bold text-red-500">R</div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router"; // Add useRouter
import { useAuthStore } from "@/stores/auth";
import html2pdf from "html2pdf.js";
import { DESCRIPTORS, QUALITIES } from "../../constants/kurtzConstants";

import { parse } from "vue/compiler-sfc";

const route = useRoute();
const router = useRouter(); // Add router
const authStore = useAuthStore();
const result = ref(null);
const loading = ref(false);

const parseJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data || [];
  }
};

// Normalize descriptors to ensure all have `name` and `checked` properties
const normalizeDescriptors = (resultDescriptors) => {
  // Parse resultDescriptors to ensure consistent format
  const parsedResultDescriptors = resultDescriptors.map((descriptor) =>
    typeof descriptor === "string"
      ? { name: descriptor, checked: true }
      : { ...descriptor, checked: true }
  );

  // Map through the full list of DESCRIPTORS and mark those in parsedResultDescriptors as checked
  return DESCRIPTORS.map((descriptor) => {
    const isChecked = parsedResultDescriptors.some(
      (resultDescriptor) => resultDescriptor.name === descriptor.name
    );
    return {
      ...descriptor,
      checked: isChecked,
    };
  });
};

// normalize the qualities to ensure all have `selected` property
const normalizeQualities = (resultQualities) => {
  return QUALITIES.map((quality) => {
    const isSelected = resultQualities.some(
      (resultQuality) =>
        resultQuality.quality1 === quality.quality1 &&
        resultQuality.quality2 === quality.quality2
    );
    return {
      ...quality,
      selected: isSelected,
    };
  });
};

// update the QUALITIES array based on the parsed results
const updatedQualities = computed(() => {
  const rawQualities = parseJSON(result.value?.qualities) || [];
  return normalizeQualities(rawQualities);
});

// Update the DESCRIPTORS array based on the parsed results
const updatedDescriptors = computed(() => {
  const rawDescriptors = parseJSON(result.value?.descriptors) || [];
  return normalizeDescriptors(rawDescriptors);
});

// Define missingDescriptors to check if any descriptors are missing
const missingDescriptors = computed(() => {
  return updatedDescriptors.value.some((descriptor) => !descriptor.checked);
});

// create a pdf with improved layout preservation and page breaks
const createPDF = () => {
  const pdfData = document.getElementById("pdf-data");
  const opt = {
    margin: [0.5, 0.5, 0.5, 0.5], // Customize margins as needed
    filename: `${result.value.Name}_Kurtz_Profile.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
      scale: 3, // Higher scale for better quality
      useCORS: true, // Ensures images are loaded correctly
    },
    jsPDF: {
      unit: "in",
      format: "letter",
      orientation: "portrait",
    },
    pagebreak: { mode: ["css", "legacy"] }, // Use CSS and legacy modes to handle page breaks
  };

  // Apply html2pdf with the options
  html2pdf().set(opt).from(pdfData).save();
};

const parsedDescriptors = computed(() => parseJSON(result.value?.descriptors) || []);
const parsedAdjectives = computed(() => parseJSON(result.value?.adjectives) || []);
const parsedColorSheets = computed(() => parseJSON(result.value?.colorSheets) || []);
const parsedWordSquare = computed(() => parseJSON(result.value?.wordSquare) || []);
const parsedQualities = computed(() => parseJSON(result.value?.qualities) || []);
const parsedOrganizationalValues = computed(
  () => parseJSON(result.value?.organizationalValues) || []
);

onMounted(async () => {
  loading.value = true;

  // Check if user is authenticated
  if (!authStore.isAuthenticated) {
    router.push("/");
    return;
  }

  const id = route.params.id;
  try {
    await authStore.fetchAllKurtzResults();
    result.value = authStore.kurtzAllResults.find((entry) => entry.$id === id);

    // Redirect if result not found
    if (!result.value) {
      router.push("/not-found");
      return;
    }
  } catch (error) {
    console.error("Error fetching results:", error);
    router.push("/");
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped>
.page-break {
  page-break-before: always;
}

#pdf-data {
  width: 100%;
  max-width: 800px; /* Adjust based on your design */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.5;
}
</style>
