<template>
  <div class="max-w-4xl mx-auto p-6 bg-white ">
      <h1 class="text-2xl font-bold mb-6 text-center">Communication Exercises Completed</h1>
      <!-- thank you message -->
      <div class="text-center">
          <p class="text-lg text-gray-700">
              Thank you for dedicating time to complete these exercises🎉 We understand that our process may feel
              lengthy, but it’s designed to ensure we find the best fit for both you and our team.
              Our evaluation process takes a comprehensive approach, considering all aspects of your application to
              determine the strongest fit with our team’s culture. This includes the communication exercises, your
              PrevueHR results, answers to application questions, and, of course, your experience!
              <br>
              <br>
              We aim to provide an update on your application within two weeks. In the meantime, please keep an eye on
              your email for any additional requests or updates from our Human Resources Team.
          </p>
      </div>
      <!-- Add attempt number display for all users -->
      <div class="mt-4 text-center text-gray-600">
        <p>Attempt #{{ authStore.kurtzResults.attempts }}</p>
      </div>
      <!-- Add error statistics if needed for admin view -->
      <div v-if="authStore.user?.isAdmin" class="mt-8 p-4 bg-gray-100 rounded">
        <h3 class="font-bold mb-4">Completion Statistics</h3>
        <ul class="space-y-2">
          <li>Attempt Number: {{ authStore.kurtzResults.attempts }}</li>
          <li>Total Time: {{ formatTime(authStore.kurtzResults.elapsedTime) }}</li>
          <li>Validation Errors:</li>
          <ul class="ml-4">
            <li v-for="(count, section) in authStore.kurtzResults.errorTracking" :key="section">
              {{ formatSectionName(section) }}: {{ count }}
            </li>
          </ul>
        </ul>
      </div>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../stores/auth";
const authStore = useAuthStore();
const router = useRouter();
onMounted(() => {
  console.log('Communication exercises completed');
  authStore.updatePrefs(
      {
          communication_exercises_completed: true
      }
  )
});

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

const formatSectionName = (name) => {
  return name
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLowerCase()
    .replace(/\b\w/g, l => l.toUpperCase());
};
</script>
<style scoped>
/* Add any custom styles if needed */
</style>