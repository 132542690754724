<template>
  <div class="w-full max-w-4xl mx-auto p-3 sm:p-1 bg-white">
    <p class="text-sm text-gray-600 mb-8 text-center">
      <span class="font-semibold uppercase">Directions:</span> Please read through all the boxes on the page before rating each box. To rate the boxes: Put a <span class="font-semibold">"4"</span> in the box that most fully describes you, a <span class="font-semibold">"3"</span> in the box that is next closest to describing you, a <span class="font-semibold">"2"</span> in the box that is the third closest to describing you, and finally a <span class="font-semibold">"1"</span> in the box which describes you the least. Keep in mind that our personalities are made up of all four "colors." What we are trying to find out is in what order they appear in your personality – which color is most dominant, the next most dominant, and so on.
    </p>

    <form
      @submit.prevent="validateAndSubmit"
      class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-2"
    >
      <div
        v-for="(colorSheet, index) in colorSheets"
        :key="index"
        :class="[colorSheet.bgClass, colorSheet.textClass]"
        class="p-3 sm:p-2 rounded-lg relative transition-colors duration-200"
      >
        <!-- <h2 class="text-lg sm:text-xl font-bold mb-1">
          {{ colorSheet.color }}
        </h2> -->

        <ul class="list-none pl-0 mb-12 sm:mb-1">
          <li
            v-for="line in colorSheet.description"
            :key="line"
            class="text-xs sm:text-sm"
          >
            {{ line }}
          </li>
        </ul>

        <input
          type="number"
          v-model="colorSheet.rank"
          min="1"
          max="4"
          class="absolute bottom-3 right-3 w-10 h-10 sm:w-12 sm:h-12 text-center text-lg border rounded-lg bg-white text-gray-800"
        />
      </div>

      <div class="col-span-1 sm:col-span-2 flex justify-end mt-4 sm:mt-8">
        <button
          type="submit"
          class="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useAuthStore } from "../../stores/auth";

const emit = defineEmits(["valid-submit"]);
const authStore = useAuthStore();

// Color Sheets Data with Bullet Points
const colorSheets = ref([
  {
    id: 1,
    color: "Yellow",
    bgClass: "bg-yellow-100",
    textClass: "text-yellow-800",
    description: [
      "I value rules, tradition, and authority.",
      "I have a clear idea of what people should do.",
      "I am loyal, dependable, punctual, trustworthy.",
      "I handle details well, and I'm a hard worker.",
      "I am useful, productive, a contributor.",
      "I like to care for others - look out for them.",
      "I want to anticipate and prepare for the future.",
      "The home and family are the core of society.",
      "It's important to have rules, laws, and controls.",
      "I appreciate awards and public recognition.",
      "I provide stability within an organization.",
      "I demonstrate my love in practical ways.",
    ],
    rank: null,
  },
  {
    id: 2,
    color: "Red",
    bgClass: "bg-red-100",
    textClass: "text-red-800",
    description: [
      "I like being free to do things my own way.",
      "Defeats are temporary.",
      "Life is a wonderful game - let's play.",
      "Where's the action (or the problem), let me at em!",
      "Variety and excitement are fun and stimulating.",
      "Give me a challenge - I'll handle it NOW!",
      "I love the spotlight - watch me perform.",
      "Let's find a new and different way to do it.",
      "Freedom is important, don't fence me in.",
      "Rules that don't make sense can be broken.",
      "Bells are for ringing, mountains for climbing.",
    ],
    rank: null,
  },
  {
    id: 3,
    color: "Blue",
    bgClass: "bg-blue-100",
    textClass: "text-blue-800",
    description: [
      "Relationships are important to me.",
      "I want to have lots of friends - share and care.",
      "I have integrity, I'm authentic and unique.",
      "I like helping others become what they can be.",
      "Emotions are okay, and I show mine easily.",
      "People are lots more important than things.",
      "I enjoy flowers, music, romantic movies.",
      "I love to help people solve their problems.",
      "My hunches work, I'm very intuitive.",
      "Empathy and sympathy are both easy for me.",
      "I thrive on recognition and acceptance.",
      "I'm really good at motivating people.",
    ],
    rank: null,
  },
  {
    id: 4,
    color: "Green",
    bgClass: "bg-green-100",
    textClass: "text-green-800",
    description: [
      "Searching, learning, understanding are fun.",
      "I love puzzles, problems, and finding solutions.",
      "I like to work independently.",
      "Intelligence, justice, and fairness are important.",
      "I want to be correct - to do things right.",
      "It would be great fun to explore the universe.",
      "Once I've found the solution, others can put it into action.",
      "My calm exterior may hide some inner turmoil.",
      "I love to create a brand new idea.",
      "Being competent is absolutely essential.",
      "I want my brain to manage my emotions.",
    ],
    rank: null,
  },
]);

watch(
  colorSheets,
  (newVal) => {
    authStore.kurtzResults.colorSheets = newVal;
  },
  { deep: true }
);

const validateRanks = () => {
  const errors = [];
  const ranks = colorSheets.value.map(sheet => Number(sheet.rank));

  // Check if all sheets are ranked
  if (ranks.some(r => !r)) {
    errors.push("Please rank all color sheets");
    return errors;
  }

  // Check if ranks are 1-4
  const validRanks = [1, 2, 3, 4];
  const hasAllRanks = validRanks.every(v => ranks.includes(v));
  if (!hasAllRanks) {
    errors.push("Each color sheet must have a unique rank from 1 to 4");
  }

  return errors;
};

const validateAndSubmit = () => {
  return new Promise((resolve, reject) => {
    const errors = validateRanks();
    if (errors.length > 0) {
      alert(errors.join('\n'));
      authStore.kurtzResults.errorTracking.colorranking++;
      reject(new Error('Validation failed'));
      return;
    }

    // Update store before emitting
    authStore.kurtzResults.colorSheets = colorSheets.value;
    emit("valid-submit");
    resolve();
  });
};

// const submitRanks = () => { ... }
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 640px) {
  /* removed yellow color override */
}
</style>
